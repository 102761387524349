import img1 from '../../../assets/images/blog/img1.png'
import img2 from '../../../assets/images/blog/img2.png'
import img3 from '../../../assets/images/blog/img3.png'
import img4 from '../../../assets/images/blog/img4.png'
import img5 from '../../../assets/images/blog/img5.png'
import img6 from '../../../assets/images/blog/img6.png'
import img7 from '../../../assets/images/blog/img7.png'
import img8 from '../../../assets/images/blog/img8.png'
import img9 from '../../../assets/images/blog/img9.png'
import img10 from '../../../assets/images/blog/img10.png'
import img11 from '../../../assets/images/blog/img11.png'
import img12 from '../../../assets/images/blog/img12.png'
import img13 from '../../../assets/images/blog/img13.png'
import img14 from '../../../assets/images/blog/img14.png'
import img15 from '../../../assets/images/blog/img15.png'
import img16 from '../../../assets/images/blog/img16.png'
import img17 from '../../../assets/images/blog/img17.png'

export const mock = [

    {
        id: 17,
        img: img17,
        title: 'Ошибки 2024 и готовность к 2025-у: успехи, провалы и большие планы!',
        date: '09 января 2025'
    },
    {
        id: 14,
        img: img14,
        title: 'Бесплатный период на ITEMBUY',
        date: '25 сентября 2024'
    },
    {
        id: 15,
        img: img15,
        title: 'Приводите друзей и получайте вознаграждения!',
        date: '25 сентября 2024'
    },
    {
        id: 16,
        img: img16,
        title: 'Почему мы решили ввести никнеймы',
        date: '25 сентября 2024'
    },
    {
        id: 6,
        img: img6,
        title: 'Что такое ITEMBUY?',
        date: '21 августа 2024'
    },
    {
        id: 10,
        img: img10,
        title: 'Когда будут аукционы?',
        date: '14 августа 2024'
    },
    {
        id: 11,
        img: img11,
        title: 'Искусство побеждать! Как участвовать в аукционах?',
        date: '8 августа 2024'
    },
    {
        id: 12,
        img: img12,
        title: 'Почему сейчас выгодно покупать?',
        date: '1 августа 2024'
    },
    {
        id: 13,
        img: img13,
        title: 'Как происходит выкуп лота?',
        date: '24 июля 2024'
    },
    {
        id: 9,
        img: img9,
        title: 'Не жди скидок - диктуй свою цену!',
        date: '17 июля 2024'
    },
    {
        id: 7,
        img: img7,
        title: 'ITEMBUY Beta: подробная информация о тестовом периоде',
        date: '10 июля 2024'
    },
    {
        id: 3, img: img3,
        title: 'Основные принципы и цели сервиса', date: '3 июля 2024'
    },
    {
        id: 1, img: img1,
        title: 'ITEMBUY - идея создания и миссия сервиса онлайн-аукционов', date: '27 июня 2024'
    },
    {
        id: 2,
        img: img2,
        short_title: 'Как мы переосмыслили аукционы в технологичное IT-решение',
        title: 'Как мы переосмыслили традиционные аукционы в технологичное IT-решение',
        date: '20 июня 2024'
    },
    {
        id: 4,
        img: img4,
        title: 'Покупка ниже рекомендованной стоимости и другие преимущества платформы',
        date: '13 июня 2024'
    },
    {
        id: 5,
        img: img5,
        short_title: 'Ответы на частые вопросы',
        title: 'Отвечаем на частые вопросы: что такое онлайн-аукционы, зачем в них участвовать и в чем выгода?',
        date: '7 июня 2024'
    },
    {
        id: 8,
        img: img8,
        title: <>Акция: До 20 000 ₽ каждому! <br/> (Акция завершена)</>,
        date: '1 июня 2024',
        isHiddenMain: true
    },

]
