import React, {useState} from 'react';
import s from './styles.module.css'
import {Avatar, Skeleton, useMediaQuery} from "@mui/material";
import Container from "../../../../components/container";
import Button from "../../../../common/ui_kit/button";
import {NavLink} from "react-router-dom";
import {mock} from "../../../0-main/4-blog/mock";
import ModalLastAuc from "./modalLastAuc";
import classNames from "classnames";
import {useGetAuctionsUpFinishQuery} from "../../../../redux/auctions.service";
import {spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import {calculatePercent, toFixed} from "../../../0-main/3-last_fihished";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";

const totalInfo = [
    {title: 'На аукционе', description: 'Покупатели сами определяют по какой цене купить товар'},
    // {title: 'В магазине', description: 'Привычные товары по самым выгодным ценам Рунета'},
    {title: 'Возможности', description: 'Экономьте до 50% на привычных товарах, участвуя в наших аукционах'},
    {title: 'Новые товары', description: 'Есть гарантия и возврат, в соответствии с ФЗ'},
    {title: 'Легко и понятно', description: 'Удобная и интуитивно понятная система'},
]

const aucData = [
    {img: null, title: 'Apple iPhone 15 Pro Мах', count: '12 лотов', price: '35 000–75 000 ₽'},
    {img: null, title: 'Samsung Galaxy S24+', count: '12 лотов', price: '25 000–65 000 ₽'},
    {img: null, title: 'Apple AirPods', count: '12 лотов', price: '500–15 000 ₽'},
]

const Page0 = ({handleReg, isAuth}) => {
    const {category: category_data} = useSelector(selectCategory)

    const query_1180 = useMediaQuery('(max-width:1150px)');
    const query_780 = useMediaQuery('(max-width:780px)');

    const [openModal, setOpenModal] = useState(false)

    const {data: finish_data, isLoading} = useGetAuctionsUpFinishQuery({
        page_size: 3,
        page: 1
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    console.log(finish_data)
    return (
        <div className={s.container}>
            {openModal && <ModalLastAuc open={openModal}
                                        handleClose={() => setOpenModal(false)}/>}

            <Container style={query_1180 ? {maxWidth: '100% !important', padding: '0'} : {}}>
                <div className={s.content}>
                    <div className={s.content_left}>
                        <div className={s.content_left_top}>
                            <h2 className={s.content_left_top_title}>
                                Маркетплейс-аукцион: <br/>
                                <span>Новый формат торговли</span> <br/>
                                Покупатель сам выбирает цену для покупок
                            </h2>

                            <p className={s.content_left_top_sub_title}>
                                3 дня бесплатных аукционов всем новым пользователям

                            </p>
                            <div className={s.content_left_top_button}>
                                <NavLink to={`${PATH.AUCTIONS}/up`}>
                                    <Button
                                        className={classNames(s.content_left_top_button_title, 'yellow_btn_reg')}>
                                        {'Перейти к аукционам'}
                                    </Button>
                                </NavLink>

                                {/*<NavLink to={PATH.MARKET}>*/}
                                {/*    <Button*/}
                                {/*        className={classNames(s.content_left_top_button_title)}>*/}
                                {/*        {'Перейти в магазин'}*/}
                                {/*    </Button>*/}
                                {/*</NavLink>*/}
                            </div>
                        </div>
                        <div className={s.content_left_bottom}>
                            {totalInfo?.map((el, i) => {
                                return <div key={i} className={s.content_left_bottom_item}>
                                    <h4 className={s.content_left_bottom_item_title}>{el.title}</h4>
                                    <p className={s.content_left_bottom_item_sub_title}>{el.description}</p>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className={s.content_right}>
                        <div className={s.content_right_auc}>
                            <div className={s.action_box}>
                                <h4 className={s.action_box_title}>Завершенные аукционы</h4>
                                <p className={s.action_box_action} onClick={() => setOpenModal(true)}>Все лоты</p>
                            </div>
                            <div className={s.content_right_auc_box}>
                                {isLoading ? <Skeleton variant="rounded"
                                                       width={`100%`}
                                                       height={'252px'}
                                                       sx={{borderRadius: '16px'}}/> : <>
                                    {finish_data?.results?.map((el, i) => {
                                        const category_slag = category_data?.results?.find((f) => f.id === el?.product?.category)
                                        const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === el?.product?.subcategory)

                                        return <div key={i} className={s.content_right_auc_box_item}>
                                            <div className={s.img_box}>
                                                <Avatar src={el?.product?.hero_image || null}
                                                        sx={{borderRadius: '8px', width: '100%', height: '100%'}}/>
                                            </div>

                                            <div className={s.content_right_auc_box_item_info}>
                                                <div className={s.content_right_auc_box_item_info_line}>
                                                    <NavLink
                                                        to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${el?.id}`}
                                                        className={s.item_title}>{el?.product?.title}</NavLink>
                                                    <p className={classNames(s.item_price)}>{`${spaceBtwHundreds(el?.highest_bid_amount || 0)} ₽`}</p>
                                                </div>
                                                <div className={s.content_right_auc_box_item_info_line}>
                                                    <p className={classNames(s.item_count)}>Экономия
                                                        <span
                                                            style={{color: '#29B942'}}>{calculatePercent(
                                                            el?.product?.retail_price, el?.highest_bid_amount) > 0 ?
                                                            ` ${spaceBtwHundreds(toFixed(calculatePercent(el?.product?.retail_price, el?.highest_bid_amount)))} %` : 0}</span>

                                                    </p>
                                                    <p className={s.item_description}>Цена продажи</p>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </>}

                            </div>
                        </div>

                        <div className={s.content_right_news}>
                            <div className={s.action_box}>
                                <h4 className={s.action_box_title}>Новости</h4>
                                <NavLink className={s.action_box_action} to={'blog'}>Все новости</NavLink>
                            </div>
                            <div className={s.content_right_news_box}>
                                {[
                                    mock?.find((f) => f.id === 17),
                                    mock?.find((f) => f.id === 15),
                                    mock?.find((f) => f.id === 16),
                                    mock?.find((f) => f.id === 6),
                                    mock?.find((f) => f.id === 13)
                                ]?.map((el) => {
                                    return <div key={el.id} className={s.content_right_news_box_item}>
                                        <NavLink to={`blog/${el.id}`}
                                                 className={s.content_right_news_box_title}>{el.short_title || el.title}</NavLink>
                                        <p className={s.content_right_news_box_date}>{el.date}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Page0;